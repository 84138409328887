<template>
	<div class="list_distribution fl_c font-cont">
		<div class="title_common fl_r">
			<img src="@/assets/img/header/indicate.png" />
			<span>农业产业概览</span>
		</div>
		<div class="fl fl_c zindex1">
			<div class="item_box" v-for="(item,index) in numData" :key="index">
				<!-- left -->
				<div class="item_box_lt">
					<!-- <div class="title">{{item.name}}</div> -->
					<img :src="item.img" />
					<span class="lt_icon"></span>
					<span class="rt_icon"></span>
				</div>
				<!-- right -->
				<div class="item_box_rt">
					<div class="item_box_rt_child" v-for="(child,childIndex) in item.numArr" :key="childIndex">
						<div class="title">{{child.name}}</div>
						<div class="num">{{child.num}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		getAgricultureStatistics
	} from "@/utils/api/index";
	import {
		mapState
	} from 'vuex';

	export default {
		name: "ListDistribution",
		props: {},
		data() {
			return {
				mTime: null,
				dataObj: {},
				numData: [{
						name: `种养农场数量`,
						img: require(`@/assets/img/agriculture_02.png`),
						icon: require(`@/assets/img/iconsm1.png`),
						color: `rgb(0, 255, 218)`,
						numArr: [{
								name: `种植场数量`,
								num: 95,
							},
							{
								name: `种植场面积`,
								num: 4737,
							},
							{
								name: `员工数量`,
								num: 322,
							},
						],
					},
					{
						name: `种养农场体量`,
						img: require(`@/assets/img/agriculture_03.png`),
						icon: require(`@/assets/img/icosm2.png`),
						color: `rgb(249, 221, 76)`,
						numArr: [{
								name: `养殖场数量`,
								num: 24,
							},
							{
								name: `养殖场面积`,
								num: 368,
							},
							{
								name: `员工数量`,
								num: 77,
							},
						],
					},
					{
						name: `特色产业`,
						img: require(`@/assets/img/agriculture_04.png`),
						icon: require(`@/assets/img/iconsm3.png`),
						color: `rgb(0, 141, 236)`,
						numArr: [{
								name: `特色产业数量`,
								num: 43,
							},
							{
								name: `种植面积`,
								num: 1925,
							},
							{
								name: `预计产量`,
								num: 19620,
							},
						],
					},
					{
						name: `农业加工`,
						img: require(`@/assets/img/agriculture_0456.png`),
						icon: require(`@/assets/img/iconsm3.png`),
						color: `rgb(0, 141, 236)`,
						numArr: [{
								name: `农业加工数量`,
								num: 43,
							},
							{
								name: `工厂面积`,
								num: 1925,
							},
							{
								name: `员工数量`,
								num: 19620,
							},
						],
					},
				],
			};
		},
		computed: {
			...mapState(['region']),
		},
		methods: {
			async getAgricultureStatistics() {
				// if (this.region.item.town_code === 'TOM1667025818620') {
				// 	this.numData[2].name = '草莓种植数量';
				// 	this.numData[2].numArr[0].name = '草莓种植数量';
				// }
				await getAgricultureStatistics({
					town: this.region.item.town_code,
					village: this.region.item.village_id,
				}).then((e) => {
					let res = e.data;
					let plantArr = this.numData[0].numArr;
					let farmArr = this.numData[1].numArr;
					let teaArr = this.numData[2].numArr;
					let factoryArr = this.numData[3].numArr;
					plantArr.map(item => {
						switch (item.name) {
							case '种植场数量':
								item.num = res.plantationNumber;
								break;
							case '种植场面积':
								item.num = res.plantationArea.toFixed(2);
								break;
							case '员工数量':
								item.num = res.plantationEmployeesNumber;
								break;
						}
					});
					farmArr.map(item => {
						switch (item.name) {
							case '养殖场数量':
								item.num = res.farmNumber;
								break;
							case '养殖场面积':
								item.num = res.farmArea.toFixed(2);
								break;
							case '员工数量':
								item.num = res.farmEmployeesNumber;
								break;
						}
					});
					teaArr.map(item => {
						switch (item.name) {
							case '特色产业数量':
								item.num = res.teaNumber;
								break;
							case '种植面积':
								item.num = res.teaArea.toFixed(2);
								break;
							case '预计产量':
								item.num = res.teaEstimatedOutput;
								break;
						}
					});
					factoryArr.map(item => {
						switch (item.name) {
							case '农业加工数量':
								item.num = res.factoryNumber;
								break;
							case '工厂面积':
								item.num = res.factoryArea.toFixed(2);
								break;
							case '员工数量':
								item.num = res.factoryEmployeesNumber;
								break;
						}
					});
					if(res.teaName != '') {
						this.numData[2].name = `${res.teaName}数量`;
						this.numData[2].numArr[0].name = `${res.teaName}数量`;
					}
				});
			},
		},
		mounted() {
			this.$nextTick((e) => {
				this.getAgricultureStatistics();
			});
		},
	};
</script>

<style scoped lang="scss">
	.list_distribution {
		width: 100%;
		height: 100%;
	}

	.title_common {
		width: 100%;
		height: 42px;
		line-height: 42px;
		display: flex;
		align-items: center;
		padding: 9px 0;
		font-size: 18px;
		font-weight: 600;
		color: #ffffff;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		font-family: PingFang SC-Bold, PingFang SC;
		background-image: url(../../assets/img/header/title_03.png);
		letter-spacing: 1px;

		img {
			width: 24px;
			height: 24px;
			margin-right: 16px;
			margin-top: 2px;
		}
	}

	.zonglang {
		font-size: 18px;
		font-weight: 500;
		color: #ffffff;
		font-family: PingFang SC-Bold, PingFang SC;
		margin-left: 50px;
	}

	.zindex1 {
		z-index: 1;
		color: #ffffff;
		margin-top: 12px;

		.item_box {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			letter-spacing: 1px;
			margin-bottom: 5px;

			.item_box_lt {
				background-image: linear-gradient(0deg, rgba(20, 26, 38, 0.80) 0%, rgba(32, 45, 70, 0.29) 100%);
				box-shadow: inset 0px 0px 30px 0px rgba(45, 93, 117, 0.2);
				width: 97px;
				display: flex;
				flex-direction: column;
				align-items: center;
				position: relative;
				padding: 6px;

				.title {
					font-size: 12px;
					color: #FFFFFF;
				}

				img {
					width: 48px;
					height: 48px;
				}

				.lt_icon {
					position: absolute;
					left: 0;
					top: 0;
					background-repeat: no-repeat;
					background-size: 100% 100%;
					background-image: url(../../assets/img/header/bevel_01.png);
					width: 10px;
					height: 10px;
				}

				.rt_icon::after {
					content: '';
					background-repeat: no-repeat;
					background-size: 100% 100%;
					background-image: url(../../assets/img/header/bevel_01.png);
					width: 10px;
					height: 10px;
					transform: scaleX(-1);
					position: absolute;
					right: 0;
					top: 0;
				}

			}

			.item_box_rt {
				flex: 1;
				margin-left: 12px;
				background-image: linear-gradient(0deg, rgba(20, 26, 38, 0.80) 0%, rgba(32, 45, 70, 0.29) 100%);
				box-shadow: inset 0px 0px 30px 0px rgba(45, 93, 117, 0.2);
				display: flex;
				align-items: center;
				height: 100%;
				justify-content: space-around;
				padding: 0 14px;

				.item_box_rt_child {
					display: flex;
					flex-direction: column;
					align-items: center;
					font-size: 12px;
					color: #7589B1;
					flex: 1;

					.num {
						font-family: DIN-Medium;
						font-size: 18px;
						color: #00E6E0;
						letter-spacing: 0;
						font-weight: 500;
						margin-top: 2px;
					}
				}
			}
		}
	}

	.f1 {
		flex: 100;
	}

	.f2 {
		flex: 30;
	}

	.f3 {
		flex: 215;
	}

	.font-width {
		width: 50%;
		height: 50%;
	}

	.num-cont {
		font-size: 20px;
	}

	.font-cont {
		font-size: 15px;
	}
</style>
