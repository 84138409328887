<template>
  <div class="agricultural_production fl_c">
    <div class="title_common">
		<img src="@/assets/img/header/indicate.png"/>
		<span>文旅产业概览</span>
	</div>
    <div class="fl fl_r content_box">
		<div ref="agriculturalBar" class="ab_style fl_r fl5 al_c ju_c"></div>
    </div>
  </div>
</template>

<script>
import { getCulturalTourismStatistics,getTalentsStatistics } from "@/utils/api/index";
import { mapState } from 'vuex'; // 从vuex中导入mapState

export default {
	name: "AgriculturalProduction",
	props: {},
	data() {
		return {
			changePieInterval: null,
			right_bar: null,
			currentIndex: -1,
			talentsEducationStatistics: [],
			data: {},
			option: {
				title : {
					show:true,//显示策略，默认值true,可选为：true（显示） | false（隐藏）
					text: '2023.08',//主标题文本，'\n'指定换行
					subtext: '额度占比',
					x: "39%",
					y: "37%",
					itemGap: 6,
					textAlign: 'center',
					textStyle: {
						fontSize: 15,
						fontWeight: '600',
						color: '#fff',
					},
				    subtextStyle: {
						fontSize: 12,
						fontWeight: '400',
						color: '#fff'
					},
				},
				tooltip: {
					trigger: 'item',
					formatter: "{b}：{c} <br/> 占比：{d}%"   //鼠标放上去 展示内容
				},
				legend: {
					textStyle: {
						color: "#fff",
					},
					orient: 'vertical',
					right: '1%',  //图例距离左的距离
					top: '25%',
				},
				series: [
					{
						name: "额度占比",
						type: "pie",
						radius: ["55%", "75%"], // 通过设置内径与外径将饼图变为圆环饼图
						center: ['40%', '50%'],  // 设置饼状图位置，第一个百分数调水平位置，第二个百分数调垂直位置
						itemStyle: {
							borderRadius: 0, // 设置圆环的圆角弧度
							color: (list) => {
								// 注意 ！！！！！ 这里的数组一定要和实际的类目长度相等或大于，不然会缺少颜色报错
								var colorList = [
									{
										colorStart:'#5CC9C9',
										colorEnd:'#aad9dc'                     
									},
									{
										colorStart:'#0062F5',
										colorEnd:'#8ab1ee'
									},
									{
										colorStart:'#F47233',
										colorEnd:'#fab999'
									},
								];
								return new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [{ //左、下、右、上
									offset: 0,
									color: colorList[list.dataIndex]['colorStart']
								}, {
									offset: 1,
									color: colorList[list.dataIndex]['colorEnd']
								}])                                    
							},
						},
						emphasis: {
							// 设置高亮时显示标签
							label: {
								show: true,
							},
							labelLine: {
								show: true,
								lineStyle: {
									color: "#fff" // 修改引导线的颜色
								}
							},
							scale: true, // 设置高亮时放大图形
							scaleSize: 7,
						},
						label: {
							color: "#fff",
							// 设置图形标签样式
							show: false, // 未高亮时不显示标签，否则高亮时显示的标签会与静态的标签重叠
							//position: "center",
							// 设置标签展示内容,其中{d}、{b}为echarts标签内容格式器
							formatter(param) {
								// correct the percentage
								if(param.percent == undefined) {
									param.percent = 0;
								}
								return param.name + ": " + param.value + "\n" + ' (' + param.percent + '%)';
							},
						},
						data: [
							{
								value: '',
								name: '观光景点',
								// itemStyle: {color:'#5CC9C9 '}
							},
							{
								value: '',
								name: '民宿',
								// itemStyle: {color:'#0062F5'}
							},
							{
								value: '',
								name: '餐饮',
								// itemStyle: {color:'#F47233'}
							},
						],
					},
				],
			},
		};
	},
	computed: {
		...mapState(['region']),  // 这里的user 指代的是模块名 /  如果是没有单独分开模块，则直接放state里的变量名
	},
	methods: {
		highlightPie() {
			// 取消所有高亮并高亮当前图形
			// 遍历饼图数据，取消所有图形的高亮效果
			for (var idx in this.option.series[0].data) {
				this.center_pie.dispatchAction({
					type: "downplay",
					seriesIndex: 0,
					dataIndex: idx,
				});
				// 高亮当前图形
				this.center_pie.dispatchAction({
				  type: "highlight",
				  seriesIndex: 0,
				  dataIndex: this.currentIndex,
				});
			}
		},
		selectPie() {
			// 高亮效果切换到下一个图形
			var dataLen = this.option.series[0].data.length;
			this.currentIndex = (this.currentIndex + 1) % dataLen;
			this.highlightPie();
		},
		salesPresentation() {
			this.center_pie = this.$echarts.init(this.$refs.agriculturalBar);
			// 当前高亮图形在饼图数据中的下标
			this.changePieInterval = setInterval(() => this.selectPie(), 4000); // 设置自动切换高亮图形的定时器
			this.center_pie.on("mouseover", (params) => {
				// 用户鼠标悬浮到某一图形时，停止自动切换并高亮鼠标悬浮的图形
				clearInterval(this.changePieInterval);
				this.currentIndex = params.dataIndex;
				this.highlightPie();
			});
			this.center_pie.on("mouseout", (params) => {
				// 用户鼠标移出时，重新开始自动切换
				if (this.changePieInterval) clearInterval(this.changePieInterval);
				this.changePieInterval = setInterval(() => this.selectPie(), 4000);
			});
			this.center_pie.setOption(this.option);
		},
		getCulturalTourismStatisticsInF() {
			getCulturalTourismStatistics({
				town: this.region.item.town_code,
				village: this.region.item.village_id
			}).then((e) => {
				const {
					turnoverList,
					attractionsNumber,
					homestayNumber,
					restaurantNumber,
				} = e.data;
				this.option.series[0].data[0].value = attractionsNumber;
				this.option.series[0].data[1].value = homestayNumber;
				this.option.series[0].data[2].value = restaurantNumber;
				this.$nextTick(() => {
					this.salesPresentation();
				})
				
			});
		},
	},
	created() {
		this.getCulturalTourismStatisticsInF();
	},
};
</script>

<style scoped lang="scss">
.agricultural_production {
  width: 100%;
  height: 100%;
}
.title_common {
  width: 100%;
  height: 42px;
  line-height: 42px;
  display: flex;
  align-items: center;
  padding: 9px 0;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-family: PingFang SC-Bold, PingFang SC;
  background-image: url(../../assets/img/header/title_03.png);
  letter-spacing: 1px;
  img {
  	width: 24px;
  	height: 24px;
  	margin-right: 16px;
  	margin-top: 2px;
  }
}

.content_box {
	background-image: linear-gradient(0deg, rgba(20,26,38,0.80) 0%, rgba(32,45,70,0.29) 100%);
	box-shadow: inset 0px 0px 30px 0px rgba(45,93,117,0.2);
	margin-top: 12px;
	//padding: 16px;
}

.item-contain {
  width: 95%;
  height: 260px;
  margin: 10px 20px;
}
.ab_style {
  width: 100%;
  height: 152px;
  //flex: 0.8;
}
.fb_style {
  width: 100px;
  height: 250px;
}
.fl5 {
  flex: 2;
}
.zindex1 {
  z-index: 1;
  color: #ffffff;
}
.img {
  width: 40px;
  // height: 40px;
}
.countfont {
  color: rgb(0, 141, 236);
  font-size: 14px;
}
.countnum {
  font-size: 22px;
}
</style>
