<template>
	<div class="one_village fl_c">
		<div class="title_common">
			<img src="@/assets/img/header/indicate.png" />
			<span>{{ listname }}</span>
		</div>
		<div class="zindex1 fl fl_c">
			<vue-seamless-scroll :data="records" :class-option="classOption" class="warp">
				<div v-for="(item,index) in records" :key="index" class="list-bg zindex1">
					<div class="al_c fl_r">
						<img src="@/assets/img/pointto_04.png" />
						<span>{{ item.name }}</span>
					</div>
					<div class="task">{{ item.task }}</div>
				</div>
			</vue-seamless-scroll>
		</div>
	</div>
</template>

<script>
	import vueSeamlessScroll from "vue-seamless-scroll";
	// import goodthings from "@/utils/data/goodthings";

	import {
		getPolict,
		getTaskList
	} from "@/utils/api/index.js";
	export default {
		name: "ScrollListInParty",
		props: {
			orderList: {
				type: Array,
				default: () => [],
			},
			listname: {
				type: String,
				default: () => `好人好事党员学习列表`,
			},
		},
		components: {
			vueSeamlessScroll,
		},
		computed: {
			classOption() {
				return {
					step: 0.2, // 数值越大速度滚动越快
					limitMoveNum: 1, // 开始无缝滚动的数据量
					hoverStop: true, // 是否开启鼠标悬停stop
					direction: 1, // 0向下 1向上 2向左 3向右
					openWatch: true, // 开启数据实时监控刷新dom
					singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
					singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
					waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
				};
			},
		},
		data() {
			return {
				goodthings: [],
				records: [],
				listData: [1, 2, 3, 4, 5, 6, 7],
				// classOption: {
				//   limitMoveNum: 0,
				//   direction: 0,
				//   step: 0.5,
				// },
			};
		},
		methods: {
			async creatMap() {
				let {
					data
				} = await getPolict();
				this.goodthings = data;
			},
			async getTaskList() {
				let {
					data
				} = await getTaskList();
				this.records = data;
			},
		},
		mounted() {
			this.getTaskList();
		},
	};
</script>

<style scoped lang="scss">
	.title_common {
		width: 100%;
		height: 42px;
		line-height: 42px;
		display: flex;
		align-items: center;
		padding: 9px 0;
		font-size: 18px;
		font-weight: 600;
		color: #ffffff;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		font-family: PingFang SC-Bold, PingFang SC;
		background-image: url(../../assets/img/header/title_03.png);
		letter-spacing: 1px;

		img {
			width: 24px;
			height: 24px;
			margin-right: 16px;
			margin-top: 2px;
		}
	}

	.list-bg {
		//height: 40px;
		background-image: linear-gradient(0deg, rgba(20, 26, 38, 0.80) 0%, rgba(32, 45, 70, 0.29) 100%);
		box-shadow: inset 0px 0px 30px 0px rgba(45, 93, 117, 0.2);
		margin-bottom: 10px;
		color: #fff;
		font-size: 14px;
		border-left: 4px solid #3877F2;
		padding: 10px 20px;

		img {
			width: 24px;
			height: 24px;
			margin-right: 12px;
		}
		
		.task {
			margin-top: 10px;
		}
	}

	.warp {
		overflow: hidden;
		height: 350px;
	}

	.zindex1 {
		z-index: 1;
		margin-top: 12px;
	}
</style>
